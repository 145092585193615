import React, {Component} from 'react';
import {Switch, Route, Link, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import LazyLoad from 'react-lazyload';
import { sendPostRequest } from "./../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';


function mapStateToProps(state) {
    return {
        PRODUCTS: state.PRODUCTS,
        CATS: state.CATS,
        GROUPED_PRODUCTS: state.GROUPED_PRODUCTS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Product extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            PRODUCT: false
        }
    }

    componentDidMount = () =>
    {
        this.getData()
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.PRODUCTS !== prevProps.PRODUCTS && this.props.PRODUCTS) {
        	this.getData();
        }
    }

    getData = () =>
    {
        let url = this.props.match.params.url;
        let PRODUCT = false;

        for (let i in this.props.PRODUCTS) {
            if (this.props.PRODUCTS[i].url == url) {
                PRODUCT = this.props.PRODUCTS[i];
            }
        }

        if (PRODUCT) {
            let CAT = false;
            for (let i in this.props.CATS) {
                if (PRODUCT.all_groups[0] == this.props.CATS[i].id) {
                    CAT = this.props.CATS[i]
                }
                if (this.props.CATS[i].children.length > 0) {
                    for (let j = 0; j < this.props.CATS[i].children.length; j++) {
                        if (PRODUCT.all_groups[0] == this.props.CATS[i].children[j].id) {
                            CAT = this.props.CATS[i].children[j]
                        }
                    }
                }
            }
            PRODUCT.cat = CAT;

            this.setState({PRODUCT: PRODUCT})
        }
    }

    renderProducts = () =>
    {
        let USE_PRODUCTS = this.props.GROUPED_PRODUCTS[this.state.CAT.id]

        let PRODUCTS = [];

        for (let i = 0; i < USE_PRODUCTS.length; i++) {
            let PRODUCT = this.props.PRODUCTS[USE_PRODUCTS[i]];
            if (typeof PRODUCT !== "undefined") {
                let SRC = CONFIG.API_C_DOMAIN + PRODUCT.big_image;
                if (!PRODUCT.big_image) {
                    SRC = '/system_images/no-image.png';
                }

                PRODUCTS.push (
                    <Link key={i} to={'/' + PRODUCT.cat.url} className="card_product_link">
                        <div>
                            <img src={SRC} />
                        </div>
                        <div className="card_product_content">
                            <div className="card_product_title">
                                {PRODUCT.title}
                            </div>
                            <div className="card_product_weight">
                                {PRODUCT.art} гр
                            </div>
                            <div className="card_product_price">
                                {PRODUCT.price} руб
                            </div>
                        </div>
                    </Link>

                )
            }
        }

        return PRODUCTS
    }

    renderImage = () =>
    {
        let SRC = CONFIG.API_C_DOMAIN + this.state.PRODUCT.big_image;
        if (!this.state.PRODUCT.big_image) {
            SRC = '/system_images/no-image.png';
        }

        return (
            <div className="big_image_holder">
                <img src={SRC} />
            </div>
        )
    }

	render() {
        if (!this.state.PRODUCT) {
            return null
        }

    	return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
                <div className="right-part">
                    <Helmet>
                        <title>{this.state.PRODUCT.title}</title>
                        <meta name="description" content={this.state.PRODUCT.title} />
                        <meta name="keywords" content={this.state.PRODUCT.title} />
                    </Helmet>
                    <div className="back_row">
                        <Link to={'/' + this.state.PRODUCT.cat.url} className="back_button">
                            <img src="/system_images/back.png"/>
                        </Link>
                        <div className="back_row_logo">
                            <img src="/system_images/logo.png" />
                        </div>
                    </div>
                    <div className="white_content">
                        {this.renderImage()}
                        <h1>{this.state.PRODUCT.title}</h1>
                        <div className="page_product_weight">
                            {this.state.PRODUCT.art} гр
                        </div>
                        <div className="description">
                            {this.state.PRODUCT.short_description}
                        </div>
                        <div className="page_product_price">
                            {this.state.PRODUCT.price} руб
                        </div>
                    </div>
                </div>
            </CSSTransitionGroup>
		);
    }
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(Product))