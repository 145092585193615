import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import Router from './SYSTEM/Router';

ReactDOM.render(
	<Router />,
  	document.getElementById('root')
)
