import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import HomePage from './../../APP/SCENES/HomePage';
import Catalog from './../../APP/SCENES/Catalog';
import Product from './../../APP/SCENES/Product';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class content extends Component {

	render() {
		return (
			<>
				<Switch>
					<Route exact path='/' component={HomePage}/>
					<Route path='/product/:url' component={Product}/>			
					<Route path='/:url' component={Catalog}/>
				</Switch>
			</>
		);
	}

}

export default connect(
	false,
	mapDispatchToProps
)(content)
