import React, {Component} from 'react';
import {Switch, Route,withRouter, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';
import InputMask from "react-input-mask";

import { sendPostRequest } from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        PRODUCTS: state.PRODUCTS,
        CATS: state.CATS,
        TOKEN: state.TOKEN
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class HomePage extends Component {

	constructor(props)
    {
        super(props);
    }

    renderCats = () =>
    {
        let LINKS = [];

        for (let i in this.props.CATS) {
            let CAT = this.props.CATS[i]

            LINKS.push(
                <Link to={'/' + CAT.url} key={i} className="menu_link">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/groups/' + CAT.image} />
                    <span>
                        {CAT.title}
                    </span>
                </Link>
            )
        }

        return LINKS;
    }

	render() {
    	return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
                <Helmet>
                    <title>Itle Steakcafe</title>
                    <meta name="description" content="Itle Steakcafe" />
                    <meta name="keywords" content="Itle Steakcafe" />
                </Helmet>
                <div className="home_images_holder">
                    <img src="/system_images/logo.png" className="logo" />
                </div>
                <div className="menu_intro">
                    Меню
                </div>
                <div className="menu_links">
                    {this.renderCats()}
                </div>
            </CSSTransitionGroup> 
		);
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(HomePage))
