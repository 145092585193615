import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import { YMaps } from 'react-yandex-maps';

/// REDUX ///
import * as ReduxActions from './../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';


/// PARTS ///

import ContentBlock from './../APP/COMPONENTS/Content.js';
import Preloader from './../APP/COMPONENTS/Preloader.js';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class App extends Component {

	constructor(props)
    {
    	super(props);
	}

	render() {
		return (
			<>
				<ContentBlock />
				<Preloader />
			</>
		);
	}

}

export default connect(
	false,
	mapDispatchToProps
)(App)
