import {Component} from 'react';
import CONFIG from './../SYSTEM/Config.js'
import { sendPostRequest } from "./../APP/HELPERS/requests"
import Cookies from 'universal-cookie';

/// REDUX ///
import * as ReduxActions from './../REDUX/functions.js'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'

const cookies = new Cookies();

function mapStateToProps(state)
{
    return {
        CART: state.CART
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class DataFetch extends Component {

	constructor(props)
    {
        super(props);

        this.state = {
        	TOKEN: false
        }
    }

    componentDidMount = () =>
    {
        this.fetchAppData();
    }

    fetchAppData = async () =>
    {
        try {
            let SEND_DATA = {
                action:    'appStartUp_v2'
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.props.setStartUpData({
                    CATS: data.groups,
                    PRODUCTS: data.products,
                    GROUPED_PRODUCTS: data.grouped_products
                });
            }
        } catch (error) {
            console.log('BAD PRODUCTS TIMER');
        }
    }

	render() {
		return false;
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DataFetch)
