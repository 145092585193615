/// CONFIG ///

const CONFIG = {
    APP_TITLE:      'Itle Steakcafe',
    API_IP: 		'62.109.20.146',
    API_DOMAIN: 	'https://itle-steakcafe-menu.ru/',
    API_C_DOMAIN: 	'https://itle-steakcafe-menu.ru',
    API_GATE: 		'https://itle-steakcafe-menu.ru/api.php',
    PHONE: 			'43543543534',
    MINIMAL: 		2000,
    CP_PUBLIC_ID: 	'sdfsdf',
    INSTAGRAM_URL:  'https://www.instagram.com/sdfsdfdsfsd/',
    PHONE: 			'+7 985 447-70-85'
};

export default CONFIG;